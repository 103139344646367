
    import { computed, defineComponent, ref } from 'vue';
    import { PartyInfo } from '@/model/PartyInfo'
    import { useRoute } from 'vue-router'
    import _partyHost from '@/services/PartyHostService'
    import _partyService from '@/services/partyService'

    export default defineComponent({
        name: 'PartyGuestPage',
        components: {
        },
        setup() {
            const partyInfo = ref(new PartyInfo('', '', ''));
            const pictureList = ref < string[] > ([]);
            const route = useRoute();

            const partyId = computed(() => {
                return route.params.partyId as string;
            });

            _partyHost.Connected.on(() => _partyHost.JoinParty(partyId.value));
            _partyHost.PartyInfoUpdated.on((s, pi) => partyInfo.value = pi);

            return {
                partyInfo,
                pictureList,
                partyId,
            }
        },
        created() {
            this.loadPictures(this.partyId);
        },
        methods: {
            loadPictures(id: string): void {
                this.pictureList.length = 0;

                _partyService.getPictureUrls(id)
                    .then(pictureList => {
                        pictureList.pictureIdList.forEach((picture) => {
                            this.pictureList.push(picture);
                        });
                    });
            },
        }
    });
