import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pictureList, (imageUrl) => {
    return (_openBlock(), _createElementBlock("div", {
      key: imageUrl,
      class: "pictureList"
    }, [
      _createVNode(_component_el_image, {
        src: '/api/picture/' + _ctx.partyId + '/' + imageUrl,
        onClick: ($event: any) => ('/api/picture/'+ _ctx.partyId + '/' + imageUrl)
      }, null, 8, ["src", "onClick"])
    ]))
  }), 128))
}