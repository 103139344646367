import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { PartyInfo } from '../model/PartyInfo';
import { TrackList } from './model/TrackList';
import { PictureList } from "./model/PictureList";
import { TrackInfo } from '../model/TrackInfo';
import { Signal } from './signal';
import { environment } from '../environment/environment';

class PartyHostService {

    private isConnected = false;
    private readonly onConnected = new Signal<PartyHostService, string>();
    private readonly onRefreshToken = new Signal<PartyHostService, string>();
    private readonly onPartyInfoUpdated = new Signal<PartyHostService, PartyInfo>();
    private readonly onTrackListUpdated = new Signal<PartyHostService, TrackList>();
    private readonly onCurrentTrackChanged = new Signal<PartyHostService, TrackInfo>();
    private readonly onPictureListUpdated = new Signal<PartyHostService, PictureList>();

    constructor() {
        this.connection.on('onConnected', () => {
            console.log('onConnected');
            this.onConnected.trigger(this, 'connected!');
        });

        this.connection.on('RefreshToken', () => {
            this.onRefreshToken.trigger(this, 'RefreshToken!');
        });

        this.connection.on('PartyInfoUpdate', (partyInfo) => {
            this.onPartyInfoUpdated.trigger(this, partyInfo);
        });

        this.connection.on('TrackListUpdate', (tracklist) => {
            this.onTrackListUpdated.trigger(this, tracklist);
        });

        this.connection.on('CurrentTrackChanged', (trackInfo) => {
            this.onCurrentTrackChanged.trigger(this, trackInfo);
        });

        this.connection.on('PictureListUpdate', (picturelist) => {
            this.onPictureListUpdated.trigger(this, picturelist);
        });

        this.connection.start();
    }

    connection = new HubConnectionBuilder()
        .withUrl(`${environment.apiUrl}/partyhost`)
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

    public get IsConnected():boolean {
        return this.isConnected;
    }

    public get Connected(): Signal<PartyHostService, string> {
        this.isConnected = true;
        return this.onConnected;
    }

    public get RefreshToken(): Signal<PartyHostService, string> {
        return this.onRefreshToken;
    }

    public get PartyInfoUpdated() :Signal<PartyHostService, PartyInfo> {
        return this.onPartyInfoUpdated;
    }

    public get TrackListUpdated() :Signal<PartyHostService, TrackList> {
        return this.onTrackListUpdated;
    }

    public get CurrentTrackChanged() :Signal<PartyHostService, TrackInfo> {
        return this.onCurrentTrackChanged;
    }

    public get PictureListUpdated() :Signal<PartyHostService, PictureList> {
        return this.onPictureListUpdated;
    }

    public StartParty(partyId: string, playerId: string): Promise<void> {
        return this.connection.invoke('startParty', partyId, playerId);
    }

    public StoptParty(partyId: string): Promise<void> {
        return this.connection.invoke('stopParty', partyId);
    }

    public AddTrack(partyId: string, trackInfo: TrackInfo): Promise<void> {
        console.log('addTrack', trackInfo);
        return this.connection.invoke('addTrack', partyId, trackInfo);
    }

    public JoinParty(partyId: string): Promise<void> {
        return this.connection.invoke('joinParty', partyId);
    }

    public StartNextTrack(partyId: string): Promise<void> {
        return this.connection.invoke('startNextTrack', partyId);
    }
}

export default new PartyHostService();
