export class UserInfo {
    constructor(
        public id: string,
        public name: string,
        public email: string,
        public picture: ImageInfo) {
        }
}

export class ImageInfo {
    constructor(
        public id: string,
        public url: string) {
        }
}
