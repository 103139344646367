
export class TrackInfo {
    constructor(
        public trackId: string = '',
        public trackUrl: string = '',
        public title: string = '',
        public artist: string = '',
        public imageSmall: string = '',
        public imageMedium: string = '',
        public imageLarge: string = '',
        public duration: number = 0,
    ) { }
}
