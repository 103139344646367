let initializeCastApi = function () {
    console.log('found chromecast');

    const context = cast.framework.CastContext.getInstance();

    context.setOptions({
        receiverApplicationId: 'E9D02770',
        autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED
    });

    const remotePlayerController = new cast.framework.RemotePlayerController(new cast.framework.RemotePlayer())
    remotePlayerController.addEventListener(
        cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
        () => {
            console.log("Remote Player event: IS_CONNECTED_CHANGED");
            window.dispatchEvent(new CustomEvent("castReceiverReady"));
        }
    );
};

function initializeReceiver(partyId) {
    var castSession = cast.framework.CastContext.getInstance().getCurrentSession();
    if (castSession) {
        castSession.sendMessage('urn:x-cast:net.partyhost.partyid-changed', {
            partyId: partyId
        });
    }
}

module.exports = { initializeCastApi, initializeReceiver }