import axios from 'axios';
import { environment } from '../environment/environment'
import { PartyInfo } from '@/model/PartyInfo'
import {
    GetPartyResponse,
    GetPartyListResponse,
    GetPictureListResponse
 } from '@/services/model/GetPartyListResponse'
import { PictureList } from "./model/PictureList";

class PartyService {

    public async createParty(partyInfo: PartyInfo): Promise<void> {
        const response = await axios.post(`${environment.apiUrl}/api/party/create`, partyInfo);

        return response.data.partyInfoList;
    } 

    public async getPartyList(): Promise<PartyInfo[]>{
        const response = await axios.get<GetPartyListResponse>(`${environment.apiUrl}/api/party/list`);
        console.log(`received response: ${response.data.partyInfoList}`);

        return response.data.partyInfoList;
    } 

    public async getParty(id: string): Promise<PartyInfo>{
        const response = await axios.get<GetPartyResponse>(`${environment.apiUrl}/api/party/` + id);
        console.log(`received response: ${response.data.partyInfo}`);

        return response.data.partyInfo;
    } 

    public async deleteParty(id: string): Promise<void> {
        const response = await axios.delete(`${environment.apiUrl}/api/party/${id}`);
        console.log(`received response: ${response.data.partyInfo}`);
    }

    public async getPictureUrls(id: string): Promise<PictureList>{
        const response = await axios.get<GetPictureListResponse>(`${environment.apiUrl}/api/picture/` + id);
        console.log(`received response: ${response.data.pictureList}`);

        return new PictureList(response.data.pictureList);
    }

    public async deletePicture(partyId: string, pictureId: string): Promise<void> {
        await axios.delete(`${environment.apiUrl}/api/picture/${partyId}/${pictureId}`);
    }
}

export default new PartyService();