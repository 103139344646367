import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-62391c19"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trackList" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "trackDetailsListItem" }
const _hoisted_5 = { class: "trackNameListItem" }
const _hoisted_6 = { class: "trackArtistListItem" }
const _hoisted_7 = {
  key: 0,
  class: "trackVotesListItem"
}
const _hoisted_8 = {
  key: 1,
  class: "trackVotesListItem"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lock = _resolveComponent("Lock")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trackList, (track, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "trackListItem",
        key: index,
        onClick: ($event: any) => (_ctx.$emit('trackSelected', track))
      }, [
        _createElementVNode("img", {
          class: "trackImageListItem",
          src: track.trackInfo.imageSmall
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(track.trackInfo.title), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(track.trackInfo.artist), 1)
        ]),
        (track.votes >= 1000)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Lock)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (track.voters.length > 1 && track.votes < 1000)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, " +" + _toDisplayString(track.voters.length), 1))
          : _createCommentVNode("", true)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}