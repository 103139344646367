import { computed, defineComponent, ref } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router'

import TrackListComponent from '../player/TrackListComponent.vue';
import PlayListComponent from '../player/PlayListComponent.vue';

import _partyHost from '@/services/PartyHostService'
import _spotifyService from '@/services/SpotifyService'
import { PartyInfo } from '@/model/PartyInfo';
import { TrackInfo } from '@/model/TrackInfo';
import { TrackQueueInfo } from '@/model/TrackQueueInfo';

/* TODO:
    const connection: HubConnection = new HubConnectionBuilder()
      .withUrl('/hub/MyHub')
      .build();

    document.AddEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && connection.state !== HubConnectionState.Connected) {
        connection.start();
      }
    }
 */

export default defineComponent({
    name: 'PartyGuestPage',
    components: {
        TrackListComponent,
        PlayListComponent,
    },
    setup() {
        const route = useRoute();
        const partyInfo = ref(new PartyInfo('', '', ''));
        const currentTrack = ref(new TrackInfo());
        const trackTimeLeft = ref(0);
        const trackSearchResult = ref<TrackInfo[]>([]);
        const query = ref('');

        const partyId = computed(() => {
            return route.params.partyId as string;
        });

        const trackMinutesLeft = computed(() => {
            return Math.floor(trackTimeLeft.value / 60000);
        })

        const trackSecondLeft = computed(() => {
            const seconds = ((trackTimeLeft.value % 60000) / 1000).toFixed(0);
            return ('00' + seconds).slice(-2);
        })

        return {
            partyInfo,
            partyId,
            currentTrack,
            trackTimeLeft,
            trackMinutesLeft,
            trackSecondLeft,
            query,
            trackSearchResult,
        }
    },
    created() {
        _partyHost.Connected.on(() => _partyHost.JoinParty(this.partyId));
        _partyHost.PartyInfoUpdated.on((s, pi) => {
            this.onPartyInfoChanged(pi)
        });
        _partyHost.CurrentTrackChanged.on((service, trackInfo) => {
            this.currentTrack = trackInfo;
            this.trackTimeLeft = this.currentTrack.duration;
        })
    },
    methods: {
        getSearchResult(searchString: string) {
            this.trackSearchResult.length = 0;

            if (searchString.length < 3)
                return;

            _spotifyService
                .getTracks(this.partyId, searchString)
                .then(trackList => {
                    trackList.forEach((track) => {
                        this.trackSearchResult.push(track);
                    });
                });
        },

        addTrack(track: TrackInfo) {
            console.log(`addTrack: ${track}`);
            _partyHost.AddTrack(this.partyId, track);
            this.trackSearchResult = [];
            this.query = "";
        },

        upvoteTrack(track: TrackQueueInfo) {
            console.log(`upvoteTrack: ${track}`);
            _partyHost.AddTrack(this.partyId, track.trackInfo);
            this.trackSearchResult = [];
        },

        onPartyInfoChanged(info: PartyInfo): void {
            this.partyInfo = info;
        },

        onPictureChanged(event: Event) {

            const input = event.target as HTMLInputElement;

            if (!input.files?.length) {
                return;
            }

            const file = input.files[0];
            const fd = new FormData();
            fd.append('partyId', this.partyId);
            fd.append('picture', file, file.name);

            console.log(file);

            //axios
            axios.post('/api/picture', fd)
                .then(result => {
                    console.log(result);
                });
        },
    }
});
