import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "trackList" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "trackDetailsListItem" }
const _hoisted_5 = { class: "trackNameListItem" }
const _hoisted_6 = { class: "trackArtistListItem" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trackList, (track, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "trackListItem",
        key: index,
        onClick: ($event: any) => (_ctx.$emit('trackSelected', track))
      }, [
        _createElementVNode("img", {
          class: "trackImageListItem",
          src: track.imageSmall
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(track.title), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(track.artist), 1)
        ])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}