
    import { defineComponent, ref } from 'vue';
    import _partyHost from '@/services/PartyHostService';
    import { TrackList } from '@/services/model/TrackList';
    import { TrackQueueInfo } from '@/model/TrackQueueInfo';

    export default defineComponent ({
        name: 'PlayListComponent',
        setup() {
            const trackList = ref<TrackQueueInfo[]>([]);

            return { trackList  }
        },
        methods: {
            updateTracklist(newList: TrackList) {
                console.log('updating tracklist:', newList)
                this.trackList.length = 0;

                newList.tracks.forEach((track) => {
                    console.log('add track:', track);
                    this.trackList.push(track);
                });
            }
        },

        created() {
            _partyHost.TrackListUpdated.on((s, data) => this.updateTracklist(data))
        }
    });
