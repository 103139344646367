// import './registerServiceWorker'
import { createApp } from 'vue';
import { createStore } from 'vuex';

import UUID from 'vue-uuid';
import VueCookies from 'vue-cookies';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import App from './App.vue';
import router from './router';
import { PartyPlayerState } from './model/PartyPlayerState';

import  VueSweetAlert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const store = createStore<PartyPlayerState>({
    state: {
        partyId: '',
        playerId: ''
    },
    mutations: {
        setPartyId(state, id: string) {
            state.partyId = id
        },
        setPlayerId(state, id: string) {
            state.playerId = id
        }
    }
})

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(store)
    .use(UUID)
    .use(VueCookies, { expires: '1d' })
    .use(ElementPlus)
    .use(VueSweetAlert2)
    .use(router)
    .mount('#app')

