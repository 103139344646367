import { computed, defineComponent, ref } from 'vue';
import PlayListComponent from '@/player/PlayListComponent.vue';
import PlayerComponent from '@/player/PlayerComponent.vue';
import VueQrcode from '@chenfengyuan/vue-qrcode';

import { PartyInfo } from '@/model/PartyInfo';
import { PartyChangedEvent } from "@/model/PartyChangedEvent";
import { PictureList } from "@/services/model/PictureList";

import _partyHost from '@/services/PartyHostService';

export default defineComponent({
    name: 'DisplayPage',
    components: {
        PlayerComponent,
        PlayListComponent,
        VueQrcode,
    },
    setup() {
        const partyId = ref('')
        const partyUri = computed(() => {
            return window.location.origin.concat("/guest/").concat(partyId.value);
        })

        const debugLog = ref<string[]>([]);
        const partyInfo = ref(new PartyInfo('', '', ''))
        const pictureList = ref<string[]>([])
        const currentPictureIndex = ref(0);

        _partyHost.Connected.on(() => {
            debugLog.value.push("connected to signalR hub");
            const initializeScript = document.createElement('script');
            initializeScript.setAttribute('src', 'initialize-receiver.js');
            document.body.appendChild(initializeScript);
        });

        window.addEventListener('partyIdChanged', ((e: CustomEvent<PartyChangedEvent>) => {
            debugLog.value.push(`partyId changed: ${e.detail.partyId}`);

            partyId.value = e.detail.partyId;
            _partyHost.JoinParty(partyId.value);

            console.log('partyId:', partyId.value);
        }) as EventListener);

        _partyHost.PartyInfoUpdated.on((s, info) => {
            debugLog.value.push(`partyId changed: ${info.id}`);
            partyInfo.value = info;
        });

        return {
            debugLog,
            partyId,
            partyInfo,
            pictureList,
            currentPictureIndex,
            partyUri
        }
    },
    mounted() {
        const castReceiverScript = document.createElement('script')
        castReceiverScript.setAttribute('src', 'https://www.gstatic.com/cast/sdk/libs/caf_receiver/v3/cast_receiver_framework.js')
        document.head.appendChild(castReceiverScript)
    },
    created() {
        console.log('partyId:', this.partyId);
        console.log('IsConnected:', _partyHost.IsConnected);

        _partyHost.PictureListUpdated.on((service, data) => {
            this.debugLog.push(`PictureListUpdated`);
            this.loadPictures(data);
        });

        setInterval(this.showNextPicture, 10000);
    },

    methods: {
        loadPictures(newList: PictureList): void {
            console.log('updating pictureList:', newList)
            this.pictureList.length = 0;

            newList.pictureIdList.reverse().forEach(pictureId => {
                this.pictureList.push(pictureId);
            });
        },

        pictureVisiblity(index: number): Record<string, boolean> {
            return {
                show: this.currentPictureIndex === index,
                hide: this.currentPictureIndex !== index,
            };
        },

        showNextPicture(): void {
            if (this.pictureList.length === 0)
                return;

            this.currentPictureIndex += 1;

            if (this.currentPictureIndex >= this.pictureList.length)
                this.currentPictureIndex = 0;

            console.log(`currentPictureIndex = ${this.currentPictureIndex}`);
        },

        openGuestPage(): void {
            window.open(this.partyUri);
        },
    },
});