import { computed, defineComponent, onBeforeUnmount, ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router'
import PlayListComponent from '@/player/PlayListComponent.vue';
import PlayerComponent from '@/player/PlayerComponent.vue';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { useRoute } from 'vue-router'

import { initializeCastApi, initializeReceiver } from '../chromecast/sender.js';

import { PartyInfo } from '@/model/PartyInfo';
import { PictureList } from "@/services/model/PictureList";

import _spotifyService from '@/services/SpotifyService'
import _partyService from '@/services/partyService';
import _partyHost from '@/services/PartyHostService';

export default defineComponent({
    name: 'HostPage',
    components: {
        PlayerComponent,
        PlayListComponent,
        VueQrcode,
    },
    setup() {
        const route = useRoute();
        const playerId = computed(() => {
            return route.query.playerId as string;
        });
        const partyId = computed(() => {
            return route.query.partyId as string;
        });
        const partyUri = computed(() => {
            return window.location.origin.concat("/guest/").concat(partyId.value);
        })

        const partyInfo = ref(new PartyInfo('', '', ''))
        const pictureList = ref<string[]>([])
        const currentPictureIndex = ref(0);

        const beforeWindowUnload = () => {
            stopPlayer();
        }

        function stopPlayer() {
            console.log("stoping the party.");

            _partyHost.StoptParty(partyId.value);
        }

        window.addEventListener('castAvailable', () => {
            initializeCastApi();
        })

        window.addEventListener('castReceiverReady', () => {
            console.log('castReceiverReady')
            initializeReceiver(partyId.value);
        })

        window.addEventListener('beforeunload', beforeWindowUnload)

        onBeforeUnmount(() => {
            console.log('beforeDestroy called...');
            window.removeEventListener('beforeunload', beforeWindowUnload);
        })

        onBeforeRouteLeave(() => {
            stopPlayer();
        })

        return {
            playerId,
            partyId,
            partyInfo,
            pictureList,
            currentPictureIndex,
            partyUri
        }
    },
    mounted() {
        const castSenderScript = document.createElement('script')
        castSenderScript.setAttribute('src', 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1')
        document.head.appendChild(castSenderScript)

        const initializeScript = document.createElement('script')
        initializeScript.setAttribute('src', 'initialize-sender.js')
        document.body.appendChild(initializeScript)
    },
    created() {
        console.log('partyId:', this.partyId);
        console.log('playerId:', this.playerId);
        console.log('IsConnected:', _partyHost.IsConnected);

        this.getPartyInfo()
            .then(() => {
                console.log('getPartyInfo: IsConnected:', _partyHost.IsConnected);
                if (_partyHost.IsConnected)
                    _partyHost.StartParty(this.partyId, this.playerId);
            });

        _partyService
            .getPictureUrls(this.partyId)
            .then(pictureList => {
                this.loadPictures(pictureList);
            });

        _partyHost.RefreshToken.on(() => {
            console.log("refresh access token.");
            _spotifyService.getAccessToken(this.partyId);
        });

        _partyHost.Connected.on(() => {
            console.log('OnConnected: IsConnected:', _partyHost.IsConnected);
            _partyHost.StartParty(this.partyId, this.playerId);
        });

        _partyHost.PictureListUpdated.on((service, data) => {
            this.loadPictures(data);
        });

        setInterval(this.showNextPicture, 10000);
    },

    methods: {
        getPartyInfo(): Promise<void> {
            return _partyService.getParty(this.partyId as string)
                .then(data => {
                    this.partyInfo = data;
                });
        },

        loadPictures(newList: PictureList): void {
            console.log('updating pictureList:', newList)
            this.pictureList.length = 0;

            newList.pictureIdList.reverse().forEach(pictureId => {
                this.pictureList.push(pictureId);
            });
        },

        pictureVisiblity(index: number): Record<string, boolean> {
            return {
                show: this.currentPictureIndex === index,
                hide: this.currentPictureIndex !== index,
            };
        },

        showNextPicture(): void {
            if (this.pictureList.length === 0)
                return;

            this.currentPictureIndex += 1;

            if (this.currentPictureIndex >= this.pictureList.length)
                this.currentPictureIndex = 0;

            console.log(`currentPictureIndex = ${this.currentPictureIndex}`);
        },

        openGuestPage(): void {
            window.open(this.partyUri);
        },
    },
});