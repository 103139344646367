
    import { computed, defineComponent, ref } from 'vue'
    import { TrackInfo } from '@/model/TrackInfo'

    import _partyHost from '@/services/PartyHostService'

    export default defineComponent ({ 
        name: 'PlayerComponent',
        props: {
            partyId: {
                type: String,
                default: '',
            },
            playerId: {
                type: String,
                default: '',
            }
        },

        setup() {
            const currentTrack = ref(new TrackInfo());
            const trackTimeLeft = ref(0);
            const token = ref('');

            const trackMinutesLeft = computed(() => {
                return Math.floor(trackTimeLeft.value / 60000);
            })

            const trackSecondLeft = computed(() => {
                const seconds = ((trackTimeLeft.value % 60000) / 1000).toFixed(0);
                return ('00' + seconds).slice(-2);
            })

            return {
                currentTrack,
                trackTimeLeft,
                token,
                trackMinutesLeft,
                trackSecondLeft,
            }
        },
        created() {
            _partyHost.CurrentTrackChanged.on((service, trackInfo) => {
                this.currentTrack = trackInfo;
                this.trackTimeLeft = this.currentTrack.duration;
            })
        },
        methods:{
            async start() {
                _partyHost.StartNextTrack(this.partyId);
            },
        },
    });
