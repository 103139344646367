
    import { defineComponent, PropType } from 'vue';
    import { TrackInfo } from '@/model/TrackInfo';

    export default defineComponent({
        name: 'TrackListComponent',
        props: {
            trackList: {
                required: true,
                type: Array as PropType<TrackInfo[]>
            }
        }
    });
