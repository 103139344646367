import { PartyInfo } from '@/model/PartyInfo'
import { DeviceInfo } from '@/model/DeviceInfo'
import { TrackInfo } from '@/model/TrackInfo'
import { UserInfo } from '../../model/UserInfo'

export class GetPartyResponse {
    constructor(public partyInfo: PartyInfo) { }
}

export class GetUserInfoResponse {
    constructor(public userInfo: UserInfo) { }
}

export class GetPartyListResponse {
    constructor(public partyInfoList : PartyInfo[])
    { }    
}

export class GetPictureListResponse {
    constructor(public pictureList : string[])
    { }
}

export class DevicesResponse {
    constructor(public devices : DeviceInfo[])
    { }
}

export class SearchRequestBody {
    constructor(
        public partyId : string,
        public query: string
    )
    { }
}

export class SearchResponseBody {
    constructor(public tracks : TrackInfo[])
    { }
}