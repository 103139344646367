import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomePage from '../home/HomePage.vue'
import AccountPage from '../account/AccountPage.vue';
import HostPage from '../host/HostPage.vue';
import PartyGuestPage from '../guest/PartyGuestPage.vue';
import DisplayPage from '../display/DisplayPage.vue';
import PicturePage from '@/pictures/PicturesPage.vue';

const routes: Array<RouteRecordRaw> = [
    {
        name: 'Home',
        path: '/',
        component: HomePage,
    },
    {
        name: 'Account',
        path: '/account',
        component: AccountPage,
    },
    {
        name: 'Party',
        path: '/party',
        component: HostPage,
    },
    {
        name: 'Guest',
        path: '/guest/:partyId',
        component: PartyGuestPage,
    },
    {
        name: 'Display',
        path: '/display',
        component: DisplayPage,
    },
    {
        name: 'Pictures',
        path: '/pictures/:partyId',
        component: PicturePage,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// router.beforeEach((to, from, next) => {
//     if (to.name !== 'Home' && !localStorage.getItem('token'))
//          next({ name: 'Home' });
//     else next();
// });

export default router